import {
  Button,
  Column,
  FormInputWrapper,
  Icon,
  Input,
  Modal,
  Row,
  Text,
} from "app/components";
import { apiRequest, handleError } from "app/utils/apiRequests";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import { getCurrentDomain, getUrlParameter } from "app/utils/utils";
import { useLocation, useNavigate } from "react-router-dom";

import AdminForm from "../components/AdminForm";
import Cookies from "js-cookie";
import { Checkbox as MUICheckbox } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { rUser } from "app/utils/recoil";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { useState } from "react";

const features = [
  "Move faster with custom AI apps and automations",
  "Trade spreadsheets for powerful, visual dashboards",
  "Control user data access with granular permissions",
];

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const AdminLogin = ({ type = "login" }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = get(location, "pathname", "");
  const urlRef = getUrlParameter("ref", location);
  const via = Cookies.get("via") || getUrlParameter("via", location);
  const ref = Cookies.get("ref") || getUrlParameter("ref", location);

  const [isSendingPasswordEmail, setIsSendingPasswordEmail] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const setUser = useSetRecoilState(rUser);

  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    // Validate form values

    let errors = {};

    if (!emailRegex.test(state.email)) {
      errors["email"] = "Valid email required";
    }

    if (state.password.length < 6) {
      errors["password"] = "Please enter a more secure password";
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const login = () => {
    const rewardfulReferral = get(window, ["Rewardful", "referral"]);
    // Make login request
    if (validate()) {
      setIsFetching(true);
      let finalData = {
        ...state,
        referral_source: ref || via,
        rewardful_referral_id: rewardfulReferral,
      };

      const endpoint = type === "signup" ? "/admin_signup/" : "/admin_login/";

      apiRequest
        .post(endpoint, finalData)
        .then((response) => {
          handleSignupResponse(response);
        })
        .catch((e) => {
          const { message } = handleError(e);
          setErrors({ password: "Invalid password" });
          setIsFetching(false);
          errorNotification(message);
        });
    }
  };

  const handleSignupResponse = (response) => {
    const error = get(response, ["data", "error"]);

    // Handle email verification
    if (type === "login") {
      const resData = get(response, ["data"]);
      const emailVerificationRequired = get(resData, [
        "app",
        "email_verification_required",
      ]);
      const resUser = get(resData, ["user"]);

      setUser(resUser);

      if (emailVerificationRequired) {
        navigate("/verify-email");
        return;
      }
    }

    // if (type === "signup") {
    //   setShowWaitlist(true);
    //   return;
    // }

    if (error) {
      const responseType = get(response, ["data", "type"]);
      errorNotification(error);
      setIsFetching(false);
      setErrors({ password: "Invalid email or password" });
    } else {
      const token = get(response, ["data", "token"]);
      Cookies.set("accessToken", token, { expires: 30 });
      setIsFetching(false);

      const responseUser = get(response, ["data", "user"]);

      mixpanel.identify(responseUser.email);

      // mixpanel.people.set({
      //   $email: responseUser.email,
      //   first_name: responseUser.first_name,
      //   last_name: responseUser.last_name,
      // });
      if (type === "signup") {
        // mixpanel.track("Create Account", {
        //   name: responseUser.first_name + " " + responseUser.last_name,
        // });
        mixpanel.track("Create Account");
      } else {
        mixpanel.track("Login");
      }

      if (type === "signup") {
        navigate("/onboarding");
      } else {
        navigate("/home");
      }
    }
  };

  const inputPadding = window.innerWidth < 800 ? "9px" : "13px";

  const headline = type === "login" ? "Login To Frontly" : "Try Frontly Today";
  const subtitle = "Build apps that fit your business perfectly";

  return (
    <PublicWrapper>
      {showForgotPassword && (
        <Modal
          minWidth="420px"
          hide={() => setShowForgotPassword(false)}
          header={{
            title: "Forgot Password",
            description: "Enter your email to request a password reset link",
          }}
        >
          <AdminForm
            isFetching={isSendingPasswordEmail}
            submit={() => {
              setIsSendingPasswordEmail(true);
              apiRequest
                .post("/forgot_password/", {
                  email: state.email,
                  domain: getCurrentDomain(),
                })
                .then((r) => {
                  const error = get(r, ["data", "error"]);
                  if (error) {
                    errorNotification(error);
                  } else {
                    successNotification("Password reset email sent");
                    setShowForgotPassword(false);
                    setIsSendingPasswordEmail(false);
                    mixpanel.track("Reset Password");
                  }
                })
                .catch((error) => {
                  console.log("ERROR", error);
                  errorNotification(get(error, "data"));
                  setIsSendingPasswordEmail(false);
                });
            }}
            errors={errors}
            submitText={"Send Password Reset Email"}
            onChange={(k, v) => setState((s) => ({ ...s, [k]: v }))}
            sectionPadding="0px"
            fields={[
              {
                id: "email",
                componentId: "Input",
                label: "Email",
                value: state.email,
              },
            ]}
          />
        </Modal>
      )}

      <Container>
        <LeftContainer>
          <Headline>{headline}</Headline>
          <Text
            data={{
              text: subtitle,
              fontSize: 24,
              fontWeight: 400,
              color: "var(--grey6)",
              margin: "0 0 30px 3px",
            }}
          />
          <FeaturesColumn>
            {features.map((f) => (
              <Row alignItems="center" gap="15px">
                <Icon
                  data={{ icon: "FiCheck", color: "var(--grey6)", size: 16 }}
                />
                <Text
                  data={{
                    text: f,
                    fontStyle: "bodyXl",
                    color: "var(--grey6)",
                    light: true,
                  }}
                />
              </Row>
            ))}
          </FeaturesColumn>
        </LeftContainer>

        <RightContainer>
          <FormContainer>
            <Logo
              src={
                "https://res.cloudinary.com/frontly/image/upload/v1737557733/frontly_black_abjsh6_hn3daa.svg"
              }
            />
            <FormInputWrapper
              label={"Email"}
              error={errors.email}
              labelStyle="bodyMdMedium"
            >
              <Input
                data={{
                  placeholder: "Email",
                  border: errors.email && "1px solid red",
                  padding: inputPadding,
                  borderRadius: "12px",
                  onChange: (v) => setState((s) => ({ ...s, email: v })),
                }}
              />
            </FormInputWrapper>
            <FormInputWrapper
              label={"Password"}
              error={errors.password}
              labelStyle="bodyMdMedium"
            >
              <Input
                data={{
                  label: "Password",
                  placeholder: "Password",
                  type: "password",
                  border: errors.password && "1px solid red",
                  padding: inputPadding,
                  borderRadius: "12px",
                  onChange: (v) => setState((s) => ({ ...s, password: v })),
                }}
              />
            </FormInputWrapper>

            {type === "signup" && (
              <Row alignItems="center" gap="10px" margin="5px 0 0 0">
                <MUICheckbox
                  color="primary"
                  checked={privacyPolicyAccepted}
                  onChange={() =>
                    setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                  }
                  value={privacyPolicyAccepted}
                  style={{ padding: "0px" }}
                  size="small"
                />

                <div style={{ fontSize: 14 }}>
                  I agree to Frontly's{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://help.frontly.ai/en/articles/8049500-frontly-privacy-policy"
                      )
                    }
                    style={{
                      color: "var(--primary-admin)",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://help.frontly.ai/en/articles/9892485-frontly-terms-of-use"
                      )
                    }
                    style={{
                      color: "var(--primary-admin)",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Terms of Use
                  </span>
                </div>
              </Row>
            )}

            <Button
              data={{
                text: type === "signup" ? "Create Account" : "Login",
                onClick: login,
                width: "100%",
                padding: inputPadding,
                borderRadius: "12px",
                size: "large",
                margin: "5px 0 0 0",
                isFetching,
              }}
            />

            <Row justifyContent="center" gap="10px" margin="10px 0 0 0">
              <Text
                data={{
                  text:
                    type === "login"
                      ? "Don't have an account?"
                      : "Already have an account?",
                  color: "var(--grey7)",
                  fontStyle: "bodyMd",
                }}
              />
              <Text
                data={{
                  text: type === "login" ? "Sign Up" : "Login",
                  color: "var(--primary-admin)",
                  fontStyle: "headingSm",
                  onClick: () =>
                    navigate(type === "login" ? "/signup" : "/login"),
                }}
              />
            </Row>
          </FormContainer>
          <Text
            data={{
              text: "Forgot password?",
              onClick: () => setShowForgotPassword(true),
              fontStyle: "bodyLg",
              margin: "25px 0 15px 0",
              color: "#000000",
            }}
          />
        </RightContainer>
      </Container>
    </PublicWrapper>
  );
};

export default AdminLogin;

const WaitlistText = styled.div`
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  text-align: center;
  max-width: 600px;
  margin-top: 30px;
  @media (max-width: 1000px) {
    font-size: 25px;
  }
`;

const PublicWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: var(--grey1);
  font-family: "Manrope", "Roboto", sans-serif;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 150px;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Headline = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
  color: var(--text);
  @media (max-width: 1000px) {
    font-size: 42px;
  }
`;

const FormContainer = styled.div`
  background: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 390px;
  gap: 16px;
  @media screen and (max-width: 800px) {
    width: 100%;
    gap: 15px;
    padding: 25px;
  }
`;

const Logo = styled.img`
  height: 54px;
  width: 100%;
  object-fit: contain;
  @media screen and (max-width: 800px) {
    height: 45px;
  }
`;
